import React, { useState } from "react";
import { Avatar, Layout } from "antd";
import { connect } from "react-redux";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_DARK,
  NAV_TYPE_SIDE,
} from "constants/ThemeConstant";
import { Scrollbars } from "react-custom-scrollbars";
import MenuContent from "./MenuContent";

const { Sider } = Layout;

export const SideNav = ({
  navCollapsed,
  sideNavTheme,
  routeInfo,
  hideGroupTitle,
  localization = true,
}) => {
  const props = {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    navCollapsed,
  };
  const userData = JSON.parse(localStorage.getItem("user_data"));

  console.log(userData);
  const [isArabic, setisArabic] = useState(
    localStorage.getItem("language") === "ar"
  );
  return (
    <Sider
      className={`side-nav ${
        sideNavTheme === SIDE_NAV_DARK ? "side-nav-dark" : ""
      } `}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
    >
      <Scrollbars autoHide>
        {/* <div
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            padding: isArabic ? "10px 20px 0 0" : "10px 0 0 20px",
            marginBottom: "20px",
          }}
          className={`${isArabic ? "rtl" : ""}`}
        >
          {navCollapsed ? (
            <>
              {userData?.profile ? (
                <Avatar
                  size={45}
                  src={`https://zomool.com/api/${userData?.profile}`}
                />
              ) : (
                <Avatar
                  style={{
                    backgroundColor: "#973F2A",
                    fontSize: "30px",
                  }}
                >
                  {userData?.fullName[0]}
                </Avatar>
              )}
            </>
          ) : (
            <>
              {userData?.profile ? (
                <Avatar
                  size={45}
                  src={`https://zomool.com/api/${userData?.profile}`}
                />
              ) : (
                <Avatar
                  style={{
                    backgroundColor: "#973F2A",
                    fontSize: "30px",
                  }}
                >
                  {userData?.fullName[0]}
                </Avatar>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  columnGap: "5px",
                }}
              >
                <span
                  style={{
                    color: "#000000",
                    fontFamily: "Almarai, sans-serif",
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  {userData.fullName}
                </span>
                <span
                  style={{
                    color: "#595858",
                    fontFamily: "Almarai, sans-serif",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  {userData.role}
                </span>
              </div>
            </>
          )}
        </div> */}

        <MenuContent type={NAV_TYPE_SIDE} {...props} />
      </Scrollbars>
    </Sider>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme } = theme;
  return { navCollapsed, sideNavTheme };
};

export default connect(mapStateToProps)(SideNav);
