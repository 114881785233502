import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
  DIR_RTL,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Emilus";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: localStorage.getItem("language") || "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: localStorage.getItem("language") === "ar" ? DIR_RTL : DIR_LTR,
};

export const STOCK_STATUS = {
  INSTOCK: "In Stock",
  OUTOFSTOCK: "Out of Stock",
};

export const STOCK_UNITS = {
  LENGTH: {
    METER: "meter",
    CENTIMETER: "centimeter",
    MILLIMETER: "millimeter",
    KILOMETER: "kilometer",
  },
  MASS: {
    GRAM: "gram",
    KILOGRAM: "kilogram",
    MILLIGRAM: "milligram",
    TON: "ton",
  },
  VOLUME: {
    LITER: "liter",
    MILLILITER: "milliliter",
    CUBIC_METER: "cubic meter",
  },
};
export const MAJOR_CATEGORY_FEEDING = {
  GRASS: "حشائش",
  GRAINS: "حبوب",
};
export const MINOR_CATEGORY_FEEDING = {
  GRASS: {
    ALFALFA: "برسيم",
    RHODES: "رودس",
    WHEATHAY: "تبن قمح",
    BARLEYHAY: "تبن شعير",
    CORNGRESS: "حشائش ذرة",
    MIXED: "مخلوط",
    PANICUM: "بونيكام",
    PANICBANK: "بونيبانك",
    OTHER: "أخرى",
  },

  GRAINS: {
    CORN: "ذرة",
    BARLEY: "شعير",
    SOYBEANCUBES: "مكعبات فول صويا",
  },
};
export const ORDER_STATUS = {
  ORDERED: { value: "ORDERED", bgColor: "#DDD" },
  PROCESSING: { value: "PROCESSING", bgColor: "#D2AAA0" },
  SHIPPING: { value: "SHIPPING", bgColor: "#7381FF" },
  DELIVERED: { value: "DELIVERED", bgColor: "#6CD179" },
};

export const PHARMACY_COUNTRIES = [
  "أوروجواى",
  "أمريكى",
  "سعودى",
  "تركى",
  "فرنسى",
  "فيتنامى",
  "إنجليزى",
  "صينى",
  "أرجنتينى",
  "أردنى",
  "باكستانى",
  "هندى",
  "مصرى",
  "أسبانى",
  "هولندى",
  "صربى",
  "كورى",
  "تيوانى",
  "ميكسيكى",
  "ألمانى",
  "سورى",
  "برازيلى",
  "بلجيكى",
  "إيطالى",
  "ماليزى",
  "نيوزلاندى",
];
