import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import i18n from "i18next/i18n";
import i18next from "i18next";
import { ArrowDown, ArrowRightIcon, LogoutIcon } from "assets/svg/icon";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { signOut } from "redux/actions/Auth";
const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};
const CustomExpandIcon = (props) => {
  const { isSubMenu, isExpanded, onExpand } = props;

  if (!isSubMenu) return null; // Ensure this is only applied to SubMenus

  return (
    <span
      style={{ fontSize: "16px", color: "black", cursor: "pointer" }}
      onClick={(e) => onExpand(e)}
    >
      {isExpanded ? <DownOutlined /> : <RightOutlined />}{" "}
    </span>
  );
};
const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    navCollapsed,
    signOut,
  } = props;
  console.log("routeInfo", routeInfo);
  const [isArabic, setIsArabic] = useState(
    localStorage.getItem("language") === "ar"
  );
  const [selectedKey, setSelectedKey] = useState(routeInfo?.key);
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const { t } = i18next;

  useEffect(() => {
    // Update selectedKey when the route changes
    setSelectedKey(routeInfo?.key);
  }, [routeInfo]);
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{
        height: "100%",
        marginTop: "2rem",
        borderRight: 0,
        padding: navCollapsed
          ? isArabic
            ? "0 10px 0 0 "
            : "0 0 0 10px"
          : isArabic
          ? "0 20px 0 0 "
          : "0 0 0 20px",
      }}
      selectedKeys={[selectedKey]} // Use selectedKey state
      onSelect={({ selectedKeys }) => setSelectedKey(selectedKeys[0])}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={`${hideGroupTitle ? "hide-group-title" : ""} ${
        isArabic ? "rtl" : ""
      }`}
      // expandIcon={(props) => <CustomExpandIcon {...props} />}
    >
      {navCollapsed ? (
        <>
          {navigationConfig.map((menu) =>
            menu.submenu.length > 0 ? (
              <Menu.ItemGroup key={menu.key}>
                {menu.submenu.map((subMenuFirst) =>
                  subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      key={subMenuFirst.key}
                      title={
                        <div>
                          {subMenuFirst.icon && (
                            <Icon
                              type={subMenuFirst?.icon}
                              style={{
                                height: "1.5rem",
                                width: "1.5rem",
                              }}
                            />
                          )}
                        </div>
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {subMenuFirst.submenu.map((subMenuSecond) =>
                        subMenuSecond.submenu.length > 0 ? (
                          <SubMenu
                            key={subMenuSecond.key}
                            title={
                              <div>
                                {subMenuSecond.icon && (
                                  <Icon
                                    type={subMenuSecond?.icon}
                                    style={{
                                      height: "1.5rem",
                                      width: "1.5rem",
                                    }}
                                  />
                                )}
                              </div>
                            }
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              paddingRight: 0,
                              paddingLeft: 0,
                            }}
                          >
                            {subMenuSecond.submenu.map((subMenuThird) =>
                              subMenuThird.submenu.length > 0 ? (
                                <SubMenu
                                  key={subMenuThird.key}
                                  title={
                                    <div>
                                      {subMenuThird.icon && (
                                        <Icon
                                          type={subMenuThird?.icon}
                                          style={{
                                            height: "1.5rem",
                                            width: "1.5rem",
                                          }}
                                        />
                                      )}
                                    </div>
                                  }
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                  }}
                                >
                                  {subMenuThird.submenu.map((subMenuFourth) => (
                                    <Menu.Item
                                      key={subMenuFourth.key}
                                      style={{
                                        backgroundColor:
                                          selectedKey === subMenuFourth.key
                                            ? "#F6F5F5"
                                            : "",
                                        borderRadius:
                                          selectedKey === subMenuFourth.key
                                            ? isArabic
                                              ? "0 20px 20px 0"
                                              : "20px 0 0 20px"
                                            : "",

                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        paddingRight: 0,
                                        paddingLeft: 0,
                                      }}
                                    >
                                      {subMenuFourth.icon && (
                                        <Icon
                                          type={subMenuFourth?.icon}
                                          style={{
                                            height: "1.5rem",
                                            width: "1.5rem",
                                          }}
                                        />
                                      )}
                                      <Link
                                        onClick={() => closeMobileNav()}
                                        to={subMenuFourth.path}
                                        // style={{
                                        //   display: "flex",
                                        //   alignItems: "center",
                                        //   justifyContent: "center",
                                        // }}
                                      >
                                        {t(subMenuFourth.title)}
                                      </Link>
                                    </Menu.Item>
                                  ))}
                                </SubMenu>
                              ) : (
                                <Menu.Item
                                  key={subMenuThird.key}
                                  style={{
                                    backgroundColor:
                                      selectedKey === subMenuThird.key
                                        ? "#F6F5F5"
                                        : "",
                                    borderRadius:
                                      selectedKey === subMenuThird.key
                                        ? isArabic
                                          ? "0 20px 20px 0"
                                          : "20px 0 0 20px"
                                        : "",

                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                  }}
                                >
                                  {subMenuThird.icon && (
                                    <Icon
                                      type={subMenuThird?.icon}
                                      style={{
                                        height: "1.5rem",
                                        width: "1.5rem",
                                      }}
                                    />
                                  )}

                                  <Link
                                    onClick={() => closeMobileNav()}
                                    to={subMenuThird.path}
                                  >
                                    {t(subMenuThird.title)}
                                  </Link>
                                </Menu.Item>
                              )
                            )}
                          </SubMenu>
                        ) : (
                          <Menu.Item
                            key={subMenuSecond.key}
                            style={{
                              backgroundColor:
                                selectedKey === subMenuSecond.key
                                  ? "#F6F5F5"
                                  : "",
                              borderRadius:
                                selectedKey === subMenuSecond.key
                                  ? isArabic
                                    ? "0 20px 20px 0"
                                    : "20px 0 0 20px"
                                  : "",

                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              paddingRight: 0,
                              paddingLeft: 0,
                            }}
                          >
                            {subMenuSecond.icon && (
                              <Icon
                                type={subMenuSecond?.icon}
                                style={{
                                  height: "1.5rem",
                                  width: "1.5rem",
                                }}
                              />
                            )}

                            <Link
                              onClick={() => closeMobileNav()}
                              to={subMenuSecond.path}
                            >
                              {t(subMenuSecond.title)}
                            </Link>
                          </Menu.Item>
                        )
                      )}
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={subMenuFirst.key}
                      style={{
                        backgroundColor:
                          selectedKey === subMenuFirst.key ? "#F6F5F5" : "",
                        borderRadius:
                          selectedKey === subMenuFirst.key
                            ? isArabic
                              ? "0 20px 20px 0"
                              : "20px 0 0 20px"
                            : "",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {subMenuFirst.icon && (
                        <Icon
                          type={subMenuFirst?.icon}
                          style={{
                            height: "1.5rem",
                            width: "1.5rem",
                          }}
                        />
                      )}

                      <Link
                        onClick={() => closeMobileNav()}
                        to={subMenuFirst.path}
                      />
                    </Menu.Item>
                  )
                )}
              </Menu.ItemGroup>
            ) : (
              <Menu.Item
                key={menu.key}
                style={{
                  backgroundColor: selectedKey === menu.key ? "#F6F5F5" : "",
                  borderRadius:
                    selectedKey === menu.key
                      ? isArabic
                        ? "0 20px 20px 0"
                        : "20px 0 0 20px"
                      : "",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingRight: 0,
                  paddingLeft: 0,
                }}
              >
                {menu.icon && (
                  <Icon
                    type={menu?.icon}
                    style={{
                      height: "1.5rem",
                      width: "1.5rem",
                    }}
                  />
                )}
                {menu.path && (
                  <Link onClick={() => closeMobileNav()} to={menu.path} />
                )}
              </Menu.Item>
            )
          )}
          <Menu.Item
            key={navigationConfig.length + 1}
            onClick={() => signOut()}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: 0,
              paddingLeft: 0,
              marginTop: "2.5rem",
            }}
          >
            <Icon
              type={LogoutIcon}
              style={{
                height: "1.5rem",
                width: "1.5rem",
              }}
            />
          </Menu.Item>
        </>
      ) : (
        <>
          {navigationConfig.map((menu) =>
            menu.submenu.length > 0 ? (
              <Menu.ItemGroup key={menu.key} title={t(menu.title)}>
                {menu.submenu.map((subMenuFirst) =>
                  subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      key={subMenuFirst.key}
                      title={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {subMenuFirst.icon && (
                            <Icon
                              type={subMenuFirst?.icon}
                              className="h-6 w-6"
                            />
                          )}
                          <span>{t(subMenuFirst.title)}</span>
                        </div>
                      }
                    >
                      {subMenuFirst.submenu.map((subMenuSecond) =>
                        subMenuSecond.submenu.length > 0 ? (
                          <SubMenu
                            key={subMenuSecond.key}
                            title={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                {subMenuSecond.icon && (
                                  <Icon
                                    type={subMenuSecond?.icon}
                                    className="h-6 w-6"
                                  />
                                )}
                                <span>{t(subMenuSecond.title)}</span>
                              </div>
                            }
                          >
                            {subMenuSecond.submenu.map((subMenuThird) =>
                              subMenuThird.submenu.length > 0 ? (
                                <SubMenu
                                  key={subMenuThird.key}
                                  title={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      {subMenuThird.icon && (
                                        <Icon
                                          type={subMenuThird?.icon}
                                          className="h-6 w-6"
                                        />
                                      )}
                                      <span>{t(subMenuThird.title)}</span>
                                    </div>
                                  }
                                >
                                  {subMenuThird.submenu.map((subMenuFourth) => (
                                    <Menu.Item
                                      key={subMenuFourth.key}
                                      style={{
                                        backgroundColor:
                                          selectedKey === subMenuFourth.key
                                            ? "#F6F5F5"
                                            : "",
                                        borderRadius:
                                          selectedKey === subMenuFourth.key
                                            ? isArabic
                                              ? "0 20px 20px 0"
                                              : "20px 0 0 20px"
                                            : "",

                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      {subMenuFourth.icon && (
                                        <Icon
                                          type={subMenuFourth?.icon}
                                          className="h-6 w-6"
                                        />
                                      )}
                                      <span>
                                        {setLocale(
                                          localization,
                                          t(subMenuFourth.title)
                                        )}
                                      </span>
                                      <Link
                                        onClick={() => closeMobileNav()}
                                        to={subMenuFourth.path}
                                      />
                                    </Menu.Item>
                                  ))}
                                </SubMenu>
                              ) : (
                                <Menu.Item
                                  key={subMenuThird.key}
                                  style={{
                                    backgroundColor:
                                      selectedKey === subMenuThird.key
                                        ? "#F6F5F5"
                                        : "",
                                    borderRadius:
                                      selectedKey === subMenuThird.key
                                        ? isArabic
                                          ? "0 20px 20px 0"
                                          : "20px 0 0 20px"
                                        : "",

                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  {subMenuThird.icon && (
                                    <Icon
                                      type={subMenuThird?.icon}
                                      className="h-6 w-6"
                                    />
                                  )}
                                  <span>
                                    {setLocale(
                                      localization,
                                      t(subMenuThird.title)
                                    )}
                                  </span>
                                  <Link
                                    onClick={() => closeMobileNav()}
                                    to={subMenuThird.path}
                                  />
                                </Menu.Item>
                              )
                            )}
                          </SubMenu>
                        ) : (
                          <Menu.Item
                            key={subMenuSecond.key}
                            style={{
                              backgroundColor:
                                selectedKey === subMenuSecond.key
                                  ? "#F6F5F5"
                                  : "",
                              borderRadius:
                                selectedKey === subMenuSecond.key
                                  ? isArabic
                                    ? "0 20px 20px 0"
                                    : "20px 0 0 20px"
                                  : "",

                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {subMenuSecond.icon && (
                              <Icon
                                type={subMenuSecond?.icon}
                                className="h-6 w-6"
                              />
                            )}
                            <span>
                              {setLocale(localization, t(subMenuSecond.title))}
                            </span>
                            <Link
                              onClick={() => closeMobileNav()}
                              to={subMenuSecond.path}
                            />
                          </Menu.Item>
                        )
                      )}
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={subMenuFirst.key}
                      style={{
                        backgroundColor:
                          selectedKey === subMenuFirst.key ? "#F6F5F5" : "",
                        borderRadius:
                          selectedKey === subMenuFirst.key
                            ? isArabic
                              ? "0 20px 20px 0"
                              : "20px 0 0 20px"
                            : "",

                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {subMenuFirst.icon && (
                        <Icon type={subMenuFirst?.icon} className="h-6 w-6" />
                      )}
                      <span>{t(subMenuFirst.title)}</span>
                      <Link
                        onClick={() => closeMobileNav()}
                        to={subMenuFirst.path}
                      />
                    </Menu.Item>
                  )
                )}
              </Menu.ItemGroup>
            ) : (
              <Menu.Item
                key={menu.key}
                style={{
                  backgroundColor: selectedKey === menu.key ? "#F6F5F5" : "",
                  borderRadius:
                    selectedKey === menu.key
                      ? isArabic
                        ? "0 20px 20px 0"
                        : "20px 0 0 20px"
                      : "",

                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {menu.icon && <Icon type={menu?.icon} className="h-6 w-6" />}
                <span className="text-[#973F2A] font-semibold text-lg">
                  {t(menu?.title)}
                </span>
                {menu.path && (
                  <Link onClick={() => closeMobileNav()} to={menu.path} />
                )}
              </Menu.Item>
            )
          )}
          <Menu.Item
            key={navigationConfig.length + 1}
            onClick={() => signOut()}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "2.5rem",
            }}
          >
            <Icon type={LogoutIcon} className="h-6 w-6" />
            <span className="text-[#973F2A] font-semibold text-lg">
              {t("Log out")}
            </span>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {menu.icon && <Icon type={menu?.icon} className="h-6 w-6" />}
                <span>{t(menu.title)}</span>
              </div>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  title={
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} className="h-6 w-6" />
                      ) : null}
                      {t(subMenuFirst.title)}
                    </span>
                  }
                >
                  {subMenuFirst.submenu.map((subMenuSecond) =>
                    subMenuSecond.submenu.length > 0 ? (
                      <SubMenu
                        key={subMenuSecond.key}
                        title={
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {subMenuSecond.icon ? (
                              <Icon
                                type={subMenuSecond?.icon}
                                className="h-6 w-6"
                              />
                            ) : null}
                            {t(subMenuSecond.title)}
                          </span>
                        }
                      >
                        {subMenuSecond.submenu.map((subMenuThird) =>
                          subMenuThird.submenu.length > 0 ? (
                            <SubMenu
                              key={subMenuThird.key}
                              title={
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  {subMenuThird.icon ? (
                                    <Icon
                                      type={subMenuThird?.icon}
                                      className="h-6 w-6"
                                    />
                                  ) : null}
                                  {t(subMenuThird.title)}
                                </span>
                              }
                            >
                              {subMenuThird.submenu.map((subMenuFourth) => (
                                <Menu.Item
                                  key={subMenuFourth.key}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  {subMenuFourth.icon && (
                                    <Icon
                                      type={subMenuFourth?.icon}
                                      className="h-6 w-6"
                                    />
                                  )}
                                  <span>{t(subMenuFourth.title)}</span>
                                  <Link to={subMenuFourth.path} />
                                </Menu.Item>
                              ))}
                            </SubMenu>
                          ) : (
                            <Menu.Item
                              key={subMenuThird.key}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {subMenuThird.icon && (
                                <Icon
                                  type={subMenuThird?.icon}
                                  className="h-6 w-6"
                                />
                              )}
                              <span>{t(subMenuThird.title)}</span>
                              <Link to={subMenuThird.path} />
                            </Menu.Item>
                          )
                        )}
                      </SubMenu>
                    ) : (
                      <Menu.Item
                        key={subMenuSecond.key}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {subMenuSecond.icon && (
                          <Icon
                            type={subMenuSecond?.icon}
                            className="h-6 w-6"
                          />
                        )}
                        <span>{t(subMenuSecond.title)}</span>
                        <Link to={subMenuSecond.path} />
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item
                  key={subMenuFirst.key}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {subMenuFirst.icon && (
                    <Icon type={subMenuFirst?.icon} className="h-6 w-6" />
                  )}
                  <span>{t(subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item
            key={menu.key}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {menu.icon && <Icon type={menu?.icon} className="h-6 w-6" />}
            <span>{t(menu.title)}</span>
            {menu.path && <Link to={menu.path} />}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle, signOut })(
  MenuContent
);
